import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Wizard } from "./wizard/Wizard";
import { DebugPage } from "./debug/Debug";
import { RenderPage } from "./cv/Render";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Wizard />,
  },
  {
    path: "debug",
    element: <DebugPage />,
  },
  {
    path: "render",
    element: <RenderPage />,
  },
]);
