import { ReactNode } from "react";
import style from "./Page.module.scss";

export interface PageProps {
  children: ReactNode;
}

export function Page(props: PageProps) {
  return <div className={style.page}>{props.children}</div>;
}
