import { Page, PageForwardProps } from "../Page";

export function IntroPage(props: PageForwardProps) {
  return (
    <Page {...props} isValid={true}>
      <>
        <h1>Kia Ora, and Welcome!</h1>
        <div>
          You are on <a href="http://warrick.co.nz">warrick.co.nz</a> where you
          can learn more about hiring me.
        </div>
        <br />
        By answering a few quick questions, I can get you a more relevant CV to
        download right now.
        <br />
        <br />
        Click next to get started!
      </>
    </Page>
  );
}
