/* tslint:disable */
/* eslint-disable */
/**
 * CV PDF Generator API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CvGenerationRequest
 */
export interface CvGenerationRequest {
    /**
     * 
     * @type {string}
     * @memberof CvGenerationRequest
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof CvGenerationRequest
     */
    _configuration: string;
}

/**
 * Check if a given object implements the CvGenerationRequest interface.
 */
export function instanceOfCvGenerationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyName" in value;
    isInstance = isInstance && "_configuration" in value;

    return isInstance;
}

export function CvGenerationRequestFromJSON(json: any): CvGenerationRequest {
    return CvGenerationRequestFromJSONTyped(json, false);
}

export function CvGenerationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CvGenerationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': json['companyName'],
        '_configuration': json['configuration'],
    };
}

export function CvGenerationRequestToJSON(value?: CvGenerationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'configuration': value._configuration,
    };
}

