import styles from "./Main.module.scss";
import React from "react";
import { Technology } from "./_Technology";
import { Story } from "./_Story";
import { StoryCategoryId, TechnologyCategoryId } from "../../../wizard/content";
import { useSelector } from "react-redux";
import { State } from "../../../state/store";
import { Page } from "../Page";
import { Content } from "../../components/Content/Content";
import { Footer } from "../../components/Footer/Footer";

export interface MainProps {
  selectedTechnologies: { [K in TechnologyCategoryId]: number };
  selectedStories: { [K in StoryCategoryId]: number };
}

export function MainPage() {
  const storyOrder = [StoryCategoryId.caseStudy, StoryCategoryId.personalStory];
  const state = useSelector<State, State>((s) => s);

  const categoryOrder = [
    TechnologyCategoryId.backend,
    TechnologyCategoryId.frontend,
    TechnologyCategoryId.cloud,
  ];

  return (
    <Page>
      <Content className={styles.content}>
        <div className={styles.mainContent}>
          <h1>Skills and experiences</h1>

          {storyOrder
            .filter((c) => state.selectedStoriesByCategory[c])
            .map((category) => (
              <Story
                key={category}
                category={category}
                storyId={state.selectedStoriesByCategory[category]!}
              />
            ))}
        </div>
        <div className={styles.sidebar}>
          {categoryOrder
            .filter((c) => state.selectedTechnologiesByCategory[c])
            .map((c) => (
              <Technology
                key={c}
                category={c}
                techId={state.selectedTechnologiesByCategory[c]!}
              />
            ))}
        </div>
      </Content>
      <Footer />
    </Page>
  );
}
