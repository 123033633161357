import styles from "./WorkHistory.module.scss";
import React from "react";
import { StoryCategoryId, TechnologyCategoryId } from "../../../wizard/content";
import { useSelector } from "react-redux";
import { State } from "../../../state/store";
import { Page } from "../Page";
import { Footer } from "../../components/Footer/Footer";
import { Content } from "../../components/Content/Content";

export interface WorkHistoryProps {
  selectedTechnologies: { [K in TechnologyCategoryId]: number };
  selectedStories: { [K in StoryCategoryId]: number };
}

export function WorkHistoryPage() {
  return (
    <Page>
      <Content>
        <h1>Work history</h1>

        <div>
          <div className={styles.titleRow}>
            <div>
              <img src="sharesies.png" />
            </div>
            <div>
              <h4>Sharesies</h4>
              <p>1 year</p>
            </div>
          </div>

          <p>
            There’s very little I haven’t touched at Sharesies. Maintaining
            automated systems for placing orders on the NZX by itself is a
            noteworthy project, mistakes made here cost very real money. Trading
            systems aside, perhaps even more important is the management and
            custody of customer assets. Ensuring securities and cash are managed
            correctly with transactions on a ledger, and that there are
            automated consistency checks in place to keep an audit of
            everything. When things are going well, I work on new features. When
            issues occur with trading, the stakes go up immediately. Being able
            to decipher the flow of assets and act with speed is paramount.
            Customers trust us with their money, and have little tolerance to
            problems affecting it. These times are when I shine, chaos doesn’t
            faze me. I’m able to consider the different stakeholders involved
            and work towards resolutions that are acceptable to the company,
            customers, and stay within the regulatory obligations.
          </p>
        </div>

        <div>
          <div className={styles.titleRow}>
            <div>
              <img src="lawvu.png" />
            </div>
            <div>
              <h4>LawVu</h4>
              <p>4 year</p>
            </div>
          </div>
          <p>
            I worked primarily as a .NET backend engineer on project management
            SaaS aimed at in-house legal teams. The most valuable skill I
            developed in this role was the ability to manage risk. Operating
            under a strict regulatory environment of ISO and other
            certifications brings many constraints to operate within that need
            consideration from all parts of the business. Legal professionals
            who used our software to complete their work understandably had zero
            tolerance to broken functionality. This is where I picked up my
            mantra “it won’t work until you know it does”. When working on
            software I place a heavy focus on building confidence that my work
            is stable. Almost always, the cost of an incident or reputational
            damage outweighs the effort it takes to adequately test changes in
            the first place. This does tie back into risk though. If the impact
            of disrupting something is low I will lean towards faster delivery.
          </p>
        </div>

        <div className={styles.callToActionContent}>
          <h1>Are you interested?</h1>
          <p className={styles.callToAction}>
            Go on, invite me for an interview.{" "}
            <img height="24px" src="icons/down-arrow.png" />{" "}
          </p>

          <ul>
            <li>
              <span>
                <img height="24px" src="icons/email.png" />
                email
              </span>
              <span>warrick@warrick.co.nz</span>
            </li>
            <li>
              <span>
                <img height="24px" src="icons/telephone.png" />
                phone
              </span>
              <span>+64 22 393 8003</span>
            </li>
          </ul>
        </div>
      </Content>
      <Footer />
    </Page>
  );
}
