/* tslint:disable */
/* eslint-disable */
/**
 * CV PDF Generator API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CvGenerationRequest,
  CvGenerationResult,
  ModelStateDictionary,
} from '../models';
import {
    CvGenerationRequestFromJSON,
    CvGenerationRequestToJSON,
    CvGenerationResultFromJSON,
    CvGenerationResultToJSON,
    ModelStateDictionaryFromJSON,
    ModelStateDictionaryToJSON,
} from '../models';

export interface CvDownloadRequest {
    id?: string;
}

export interface CvGenerateRequest {
    model: CvGenerationRequest;
}

export interface CvStateRequest {
    id?: string;
}

/**
 * 
 */
export class CvApi extends runtime.BaseAPI {

    /**
     */
    async cvDownloadRaw(requestParameters: CvDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cv/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async cvDownload(requestParameters: CvDownloadRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.cvDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cvGenerateRaw(requestParameters: CvGenerateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CvGenerationResult>> {
        if (requestParameters.model === null || requestParameters.model === undefined) {
            throw new runtime.RequiredError('model','Required parameter requestParameters.model was null or undefined when calling cvGenerate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/cv/generate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CvGenerationRequestToJSON(requestParameters.model),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CvGenerationResultFromJSON(jsonValue));
    }

    /**
     */
    async cvGenerate(requestParameters: CvGenerateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CvGenerationResult> {
        const response = await this.cvGenerateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async cvStateRaw(requestParameters: CvStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cv/state`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async cvState(requestParameters: CvStateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.cvStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
