import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import store from "./state/store";
import { Provider } from "react-redux";
import { router } from "./router";
import { RouterProvider } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);
