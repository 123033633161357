import styles from "./Content.module.scss";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { State } from "../../../state/store";
import cn from "classnames";

export interface ContentProps {
  className?: string;
  children?: ReactNode;
}

export function Content(props: ContentProps) {
  return (
    <div className={cn(props.className, styles.content)}>{props.children}</div>
  );
}
