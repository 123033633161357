import { caseStudyContent } from "./caseStudyContent";
import { CaseStudyContent, StoryCategoryContent } from "./types";
import { personalStoryContent } from "./personalStoryContent";
import { StoryCategoryId } from "../../../wizard/content";

export const storiesByCategory: {
  [K in StoryCategoryId]: StoryCategoryContent;
} = {
  [StoryCategoryId.caseStudy]: caseStudyContent,
  [StoryCategoryId.personalStory]: personalStoryContent,
};
