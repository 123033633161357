import { ReactNode, useEffect } from "react";

export interface PageForwardProps {
  isValidChanged?: (isValid: boolean) => void;
}

export interface PageProps extends PageForwardProps {
  className?: string;
  isValid: boolean;
  children: ReactNode;
}

export function Page(props: PageProps) {
  useEffect(() => {
    if (props.isValidChanged) {
      props.isValidChanged(props.isValid);
    }
  }, [props.isValid]);

  return <div className={props.className}>{props.children}</div>;
}
