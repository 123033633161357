import { storiesByCategory } from "../../content/stories/stories";
import { StoryCategoryId } from "../../../wizard/content";

export interface StoryProps {
  category: StoryCategoryId;
  storyId: number;
}

export function Story(props: StoryProps) {
  const category = storiesByCategory[props.category];
  const story = category.storiesById[props.storyId];

  return (
    <div>
      <h4>{category.title}</h4>
      <p>{story.subtitle}</p>
      {story.content}
    </div>
  );
}
