import { backendContent } from "./technologies/backendTechnologies";
import { frontendContent } from "./technologies/frontendTechnologies";
import { cloudContent } from "./technologies/cloudTechnologies";
import { TechnologyCategoryContent } from "./types";
import { TechnologyCategoryId } from "../../wizard/content";

export const technologyContent: {
  [K in TechnologyCategoryId]: TechnologyCategoryContent<number>;
} = {
  [TechnologyCategoryId.backend]: backendContent,
  [TechnologyCategoryId.frontend]: frontendContent,
  [TechnologyCategoryId.cloud]: cloudContent,
};
