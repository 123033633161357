import { ContentChoices } from './content';

export enum TechnologyCategoryId {
  backend,
  frontend,
  cloud,
}

export enum BackendTechnology {
  notRelevant,
  dotnet,
  python,
  c,
  java,
  other,
}

export enum FrontendTechnology {
  notRelevant,
  react,
  angular,
  other,
}

export enum CloudTechnology {
  azure,
  aws,
  other,
}

export const technologies: {[K in TechnologyCategoryId]: ContentChoices<TechnologyCategoryId, number>} = {
  [TechnologyCategoryId.backend]: {
    id: TechnologyCategoryId.backend,
    title: "Backend technology",
    description: "Just pick the most relevant one to the role",
    options: [
      {
        id: BackendTechnology.notRelevant,
        title: "Not relevant",
      },
      {
        id: BackendTechnology.dotnet,
        title: "C# / .NET",
      },
      {
        id: BackendTechnology.python,
        title: "Python",
      },
      {
        id: BackendTechnology.c,
        title: "C / C++",
      },
      {
        id: BackendTechnology.java,
        title: "Java",
      },
      {
        id: BackendTechnology.other,
        title: "Other",
      },
    ],
  },
  [TechnologyCategoryId.frontend]: {
    id: TechnologyCategoryId.frontend,
    title: "Frontend technology",
    description: "Just pick the most relevant one to the role",
    options: [
      {
        id: FrontendTechnology.notRelevant,
        title: "Not relevant",
      },
      {
        id: FrontendTechnology.react,
        title: "React JS",
      },
      {
        id: FrontendTechnology.angular,
        title: "Angular",
      },
      {
        id: FrontendTechnology.other,
        title: "Other",
      },
    ],
  },
  [TechnologyCategoryId.cloud]: {
    id: TechnologyCategoryId.cloud,
    title: "What Cloud technology is relevant?",
    description: "",
    options: [
      {
        id: CloudTechnology.azure,
        title: "Azure",
      },
      {
        id: CloudTechnology.aws,
        title: "AWS",
      },
      {
        id: CloudTechnology.other,
        title: "Other / None",
      },
    ],
  }
}