import { CaseStudyContent, StoryCategoryContent } from "./types";
import { PersonalStoryId } from "../../../wizard/content";
export const personalStoryContent: StoryCategoryContent = {
  title: "Tales from my time",
  storiesById: {
    [PersonalStoryId.crashedPlane]: {
      subtitle: "Yes, I really did nearly crash a plane.",
      content: (
        <>
          In 2019 I was training for my Private Pilots Licence. On a solo flight
          after the instructor had got out, the door was not latched closed
          correctly. During my takeoff roll I became distracted with attempting
          to close the door, and when I looked up there was a runway sign
          looming in front of me and I was about to veer off the runway.
          <br />I pulled back and luckily had enough speed to climb off the
          runway and over the sign. Really stupid. Should have focused on the
          important task, flying the plane. Valuable lesson in priorities
          though, I suppose.
        </>
      ),
    },
    [PersonalStoryId.bank]: {
      subtitle: "Reverse engineering software can be fun",
      content: (
        <>
          Once upon a time, my NZ bank didn't have an Android app. So I made
          one.
          <br />
          This wasn't a straightforward process, because at face value their
          online app used a complex process of application level encryption to
          encrypt all requests between client and server.
          <br />
          It took time, but I was able to reverse engineer the steps involved in
          this encryption - a combination of AES, PBKDF2 and hashes. I then
          built a wrapping API that simplified this extraordinary goose chase
          into something consumable by the mobile app that I also built.
          <br />
          Then I published it all on Github.
          <br />I was definitely a bit brash in my claims that the application
          level encryption was poorly done - if you could SSL terminate and
          observe the traffic, you wouldn't see the keys, but you could deduce
          them, rendering it nothing more than added obscurity.
          <br />
          Anyway, they politely asked that I take that down so I did. They have
          a new system now - it has mobile apps.
        </>
      ),
    },
    [PersonalStoryId.smoke]: {
      subtitle: "Working on electronic projects keeps my brain sharp",
      content: (
        <>
          I'm a keen electrical engineer in my spare time. When designing a CAN
          bus controlled low voltage power switch and protection circuit my buck
          converter IC would randomly blow up. I must have gone through 10 of
          these chips before I had figured out what was happening.
          <br />
          Because I was powering my circuit with a cheap bench power supply, it
          had a very inductive output. I eventually figured out that when
          connecting my circuit to power, the capacitors on board would draw an
          unconstrained spike of current. The power supply would respond by
          increasing it's current output, but the voltage rail would then spike
          as the capacitors finished charging.
          <br />I solved this by limiting the startup current of my circuit with
          a resistor, and adding voltage clamping diodes across the power rail.
          Not it's bulletproof.
        </>
      ),
    },
    [PersonalStoryId.planeBuild]: {
      subtitle: "I'm kinda into airplanes",
      content: (
        <>
          I'm building a 2 seater Zenith STOL 750 aircraft kit imported from the
          states. My single car garage isn't big enough to complete the project,
          but I can build each wing and the fuselage separately before needing
          to move elsewhere.
          <br />
          It's an all aluminium sheet metal construction. You'd think it's
          difficult, but it's really not. Most of the parts are CNC cut and
          drilled, so if the holes between parts align you're good to pull the
          rivets.
        </>
      ),
    },
  },
};
