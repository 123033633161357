import { TechnologyCategoryContent, TechnologyContent } from "../types";
import { CloudTechnology } from "../../../wizard/content";

export const cloudContent: TechnologyCategoryContent<CloudTechnology> = {
  title: "Cloud and Infrastructure",
  content: {
    [CloudTechnology.aws]: {
      subtitle: "AWS",
      content: (
        <>
          The grandfather of cloud providers. The most common services I use are
          ECS tasks and clusters, relational database, SQS queues and S3.
          <br />
          I'm very familiar with the AWS console, and am able to provision,
          manage and monitor resources effectively.
          <br />I haven't spent a large amount of time deploying infrastructure
          as code, but when suitable that is my preference to maintain
          consistency across resources.
        </>
      ),
    },
    [CloudTechnology.azure]: {
      subtitle: "Microsoft Azure",
      content: (
        <>
          The most common services I've used are App Services, SQL databases,
          Redis cache and blob storage.
          <br />
          There's an Azure product for everything. I'm very familiar with both
          managing and monitoring infrastructure in the portal.
          <br />I haven't spent a large amount of time deploying infrastructure
          as code, but when suitable that is my preference to maintain
          consistency across resources.
        </>
      ),
    },
    [CloudTechnology.other]: {
      subtitle: "Knowledge and experience",
      content: (
        <>
          While I have detailed experience with cloud providers like Azure and
          AWS, I'm very familiar with linux systems and am comfortable managing
          servers and other infrastructure.
        </>
      ),
    },
  },
};
