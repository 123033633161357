import styles from "./CV.module.scss";
import React from "react";
import { CoverPage } from "./Cover/CoverPage";
import { MainPage } from "./Main/Main";
import { WorkHistoryPage } from "./WorkHistory/WorkHistory";

const cvPages = [<CoverPage />, <MainPage />, <WorkHistoryPage />];

export function CV() {
  return (
    <div className={styles.background}>
      {cvPages.map((p, i) => (
        <div className={styles.a4} key={i}>
          {p}
        </div>
      ))}
    </div>
  );
}
