import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../state/store";
import { setCompanyName } from "../../../state/actions";
import { Page, PageForwardProps } from "../Page";

export function JobInfo(props: PageForwardProps) {
  const name = useSelector<State, string>((state) => state.companyName);
  const dispatch = useDispatch();
  return (
    <Page {...props} isValid={name.trim() != ""}>
      <h1>What is your company name?</h1>
      <p>This will be shown in the generated CV you download.</p>

      <input
        placeholder="Company name"
        value={name}
        onChange={(event) =>
          dispatch(setCompanyName({ name: event.target.value }))
        }
      />
    </Page>
  );
}
