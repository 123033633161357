import styles from "./ExpandableSelectList.module.scss";
import cn from "classnames";
import { useEffect, useState } from "react";

export interface ExpandableSelectListItems {
  id: any;
  title: string;
}

export interface ExpandableSelectListProps {
  isExpanded?: boolean;
  className?: string;
  title: string;
  options: ExpandableSelectListItems[];
  onChange?: (item?: ExpandableSelectListItems) => void;
}

export function ExpandableSelectList(props: ExpandableSelectListProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ExpandableSelectListItems>();

  useEffect(
    () => props.onChange && props.onChange(selectedItem),
    [selectedItem]
  );

  useEffect(() => {
    if (props.isExpanded !== undefined) setIsExpanded(props.isExpanded);
  }, [props.isExpanded]);

  return (
    <div className={cn(props.className, styles.component)}>
      <div className={styles.header} onClick={() => setIsExpanded(!isExpanded)}>
        <span className={styles.title}>
          {props.title}
          {selectedItem && <>: {selectedItem?.title}</>}
        </span>

        {selectedItem === undefined && (
          <span className={cn(styles.status, "material-symbols-outlined")}>
            expand_more
          </span>
        )}

        {selectedItem !== undefined && (
          <span className={cn(styles.status, "material-symbols-outlined")}>
            task_alt
          </span>
        )}
      </div>

      {isExpanded && (
        <div className={styles.optionContainer}>
          {props.options.map((item) => {
            return (
              <div
                key={item.id}
                className={cn(
                  styles.option,
                  selectedItem?.id === item.id ? styles.active : undefined
                )}
                onClick={() => {
                  setSelectedItem(item);
                  setIsExpanded(false);
                }}
              >
                <span>{item.title}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
