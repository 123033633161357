import { ExpandableSelectList } from "../../components/ExpandableSelectList/ExpandableSelectList";
import styles from "./TechnologyChoices.module.scss";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTechnologyChoice } from "../../../state/actions";
import { State } from "../../../state/store";
import { technologies, TechnologyCategoryId } from "../../content";
import { Page, PageForwardProps } from "../Page";

export function TechnologyChoices(props: PageForwardProps) {
  const selectedTechnologyByCategory = useSelector<
    State,
    { [K in TechnologyCategoryId]: number | undefined }
  >((state) => state.selectedTechnologiesByCategory);

  const dispatch = useDispatch();

  const technologyCategories = [
    TechnologyCategoryId.backend,
    TechnologyCategoryId.frontend,
    TechnologyCategoryId.cloud,
  ];

  const [visibleChoice, setVisibleChoice] = useState<
    TechnologyCategoryId | undefined
  >(technologyCategories[0]);

  return (
    <Page
      {...props}
      className={styles.page}
      isValid={
        Object.values(selectedTechnologyByCategory).filter(
          (x) => x === undefined
        ).length === 0
      }
    >
      <h1>What technologies do you use?</h1>
      <p>
        Just pick the <strong>most</strong> relevant
      </p>

      {technologyCategories.map((category) => {
        const choice = technologies[category];
        return (
          <ExpandableSelectList
            key={choice.id}
            className={styles.item}
            title={choice.title}
            options={choice.options}
            isExpanded={visibleChoice === choice.id}
            onChange={(item) => {
              if (!item) return;

              const nextChoiceIndex = technologyCategories.findIndex(
                (c) =>
                  c !== choice.id &&
                  selectedTechnologyByCategory[c] === undefined
              );

              if (nextChoiceIndex !== -1)
                setVisibleChoice(technologyCategories[nextChoiceIndex]);
              else setVisibleChoice(undefined);

              dispatch(
                setTechnologyChoice({
                  category: choice.id,
                  technology: item.id,
                })
              );
            }}
          />
        );
      })}
    </Page>
  );
}
