import { TechnologyCategoryContent } from "../types";
import { FrontendTechnology } from "../../../wizard/content";

export const frontendContent: TechnologyCategoryContent<FrontendTechnology> = {
  title: "Frontend and UI",
  content: {
    [FrontendTechnology.notRelevant]: {
      subtitle: "",
      content: <></>,
    },
    [FrontendTechnology.react]: {
      subtitle: "React JS",
      content: (
        <>
          I've worked on large projects with React, redux and everything else
          that comes with it.
          <br />I find the flexibility of React makes development very quick,
          and this is my framework of choice for most web projects.
        </>
      ),
    },
    [FrontendTechnology.angular]: {
      subtitle: "Angular",
      content: (
        <>
          I've worked on a few complex products built in Angular, and find it to
          be a well suited framework for large web applications.
          <br />
          The type safety you get with Typescript provides some security when
          maintaining such large applications.
        </>
      ),
    },
    [FrontendTechnology.other]: {
      subtitle: "Knowledge and experience",
      content: (
        <>
          I can write javascript (who can't), and I can write typescript that
          takes full advantage of the safety it can bring.
          <br />
          Whatever frontend framework you're using, it's not rocket science and
          I can delivery value almost immediately.
        </>
      ),
    },
  },
};
