import { createAction } from "@reduxjs/toolkit";
import { StoryCategoryId, TechnologyCategoryId } from "../wizard/content";

export const setCompanyName = createAction<{
  name: string;
}>("setCompanyName");

export const setTechnologyChoice = createAction<{
  category: TechnologyCategoryId;
  technology: number;
}>("setTechnologyChoice");

export const setContentChoice = createAction<{
  category: StoryCategoryId;
  contentId: number;
}>("setContentChoice");

export const setState = createAction<any>("setState");
