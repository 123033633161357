import styles from "./Download.module.scss";
import { useQuery } from "react-query";
import { Loading } from "./_Loading";
import { Download } from "./_Download";
import { GeneratorApi } from "../../../generatorApi";
import { useSelector } from "react-redux";
import { State } from "../../../state/store";

export interface DownloadPageProps {
  isStateValid?: boolean;
  isVisible?: boolean;
}

export function DownloadPage(props: DownloadPageProps) {
  const state = useSelector<State, State>((state) => state);

  const downloadQuery = useQuery({
    queryKey: [state],
    queryFn: () =>
      GeneratorApi.cvGenerate({
        model: {
          companyName: state.companyName,
          _configuration: JSON.stringify(state),
        },
      }),
    enabled: props.isStateValid === true,
  });

  return (
    <div className={styles.page}>
      {downloadQuery.isFetching && <Loading />}
      {!downloadQuery.isFetching && downloadQuery.data !== undefined && (
        <Download result={downloadQuery.data} />
      )}
    </div>
  );
}
