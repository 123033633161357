import { configureStore, createReducer } from "@reduxjs/toolkit";
import {
  setCompanyName,
  setContentChoice,
  setState,
  setTechnologyChoice,
} from "./actions";
import { StoryCategoryId, TechnologyCategoryId } from "../wizard/content";

export interface State {
  companyName: string;
  pdfId?: string;
  selectedTechnologiesByCategory: {
    [K in TechnologyCategoryId]: number | undefined;
  };
  selectedStoriesByCategory: { [K in StoryCategoryId]: number | undefined };
}

export default configureStore({
  reducer: createReducer<State>(
    {
      companyName: "",
      selectedTechnologiesByCategory: {
        [TechnologyCategoryId.backend]: undefined,
        [TechnologyCategoryId.frontend]: undefined,
        [TechnologyCategoryId.cloud]: undefined,
      },
      selectedStoriesByCategory: {
        [StoryCategoryId.personalStory]: undefined,
        [StoryCategoryId.caseStudy]: undefined,
      },
    },
    (builder) => {
      builder.addCase(setTechnologyChoice, (state, action) => {
        state.selectedTechnologiesByCategory[action.payload.category] =
          action.payload.technology;
      });
      builder.addCase(setCompanyName, (state, action) => {
        state.companyName = action.payload.name;
      });
      builder.addCase(setContentChoice, (state, action) => {
        state.selectedStoriesByCategory[action.payload.category] =
          action.payload.contentId;
      });
      builder.addCase(setState, (state, action) => {
        Object.assign(state, action.payload);
      });
    }
  ),
});
