import { useQuery } from "react-query";
import { GeneratorApi } from "../generatorApi";
import { useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setState } from "../state/actions";
import styles from "./Render.module.scss";
import { CV } from "./pages/CV";
import { State } from "../state/store";

export function RenderPage() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const state = useSelector<State, State>((s) => s);

  const downloadQuery = useQuery("state", () =>
    GeneratorApi.cvState({ id: searchParams.get("id") || undefined })
  );

  useEffect(() => {
    if (downloadQuery.isFetched)
      dispatch(setState(JSON.parse(downloadQuery.data!)));
  }, [downloadQuery.isFetched]);

  useEffect(() => {
    // Tell the PDF render we're ready
    if (state.companyName !== "") {
      document.body.dispatchEvent(new Event("view-ready"));
      console.log("Fired view-ready event!");
    }
  }, [state.companyName]);

  return (
    <div className={styles.background}>
      <CV />
    </div>
  );
}
