import styles from "./CoverPage.module.scss";
import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { State } from "../../../state/store";
import { Footer } from "../../components/Footer/Footer";
import { Page } from "../Page";
import { Content } from "../../components/Content/Content";

export function CoverPage() {
  const state = useSelector<State, State>((s) => s);

  return (
    <Page>
      <Content>
        <div className={styles.contentContainer}>
          <div className={styles.pictureContainer}>
            <img src="me.png" />
            <div className={styles.imageAnnotation}>
              <img className={styles.arrow} src="icons/arrow_down_left.png" />
              <span>
                Me picking cherries{" "}
                <img className={styles.cherries} src="icons/cherries.png" />
              </span>
            </div>
          </div>
          <h1 className={styles.name}>Warrick Walter</h1>
          <p className={styles.createdFor}>
            Curated for{" "}
            <strong>{state.companyName || "ACME Corporation"}</strong> on{" "}
            <strong>warrick.co.nz</strong>
          </p>

          <div className={styles.attributeContainer}>
            <h4>
              <img src="icons/time.png" /> Decisive Execution
            </h4>
            <p>
              I design for the future, but don't waste time in the present. If
              decisions we can make today won’t hold us back tomorrow, what are
              we waiting for?
            </p>

            <h4>
              <img src="icons/light-bulb.png" />
              Imaginative problem solving
            </h4>
            <p>
              It’s easy to follow the script, but knowing when to flip the
              script comes with experience.
            </p>

            <h4>
              <img src="icons/care.png" />
              Supportive interactions
            </h4>
            <p>
              Excellence is not achieved alone. I aim to uplift those around me,
              and contribute to the advancement of the people I work with.
            </p>
          </div>
        </div>
      </Content>
      <Footer>
        <img
          className={styles.bottomChevron}
          height="40"
          src="icons/more_below.png"
        />
      </Footer>
    </Page>
  );
}
