import { Carousel } from "react-responsive-carousel";
import { IntroPage } from "./pages/Intro/Intro";
import cn from "classnames";
import React, { cloneElement, useState } from "react";
import styles from "./Wizard.module.scss";
import { DownloadPage } from "./pages/Download/Download";
import { ContentChooser } from "./pages/ContentChooser/ContentChooser";
import { JobInfo } from "./pages/JobInfo/JobInfo";
import { TechnologyChoices } from "./pages/TechnologyChoices/TechnologyChoices";
import { StoryCategoryId } from "./content";
import { PageForwardProps } from "./pages/Page";

export function Wizard() {
  const [activePage, setActivePage] = useState(0);
  const [validPages, setValidPages] = useState(new Set<number>([0]));

  const pages = [
    <IntroPage />,
    <JobInfo />,
    <TechnologyChoices />,
    <ContentChooser category={StoryCategoryId.caseStudy} />,
    <ContentChooser category={StoryCategoryId.personalStory} />,
    <DownloadPage isStateValid={validPages.size === 5} />,
  ].map((page, index) =>
    cloneElement<PageForwardProps>(page, {
      ...page.props,
      isValidChanged: (isValid) => {
        const valid = new Set(validPages);

        if (isValid) valid.add(index);
        else valid.delete(index);

        setValidPages(valid);
      },
    })
  );

  return (
    <div className={styles.page}>
      <div className={styles.mainContent}>
        <Carousel
          className={cn(styles.container, styles.carousel)}
          showIndicators={false}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          swipeable={false}
          onChange={(page) => setActivePage(page)}
          selectedItem={activePage}
        >
          {pages.map((p, i) => (
            <div key={i}>{p}</div>
          ))}
        </Carousel>
      </div>

      <div className={cn(styles.footer)}>
        <div className={cn(styles.container, styles.footerContent)}>
          <button
            style={{ visibility: activePage === 0 ? "hidden" : undefined }}
            onClick={() => setActivePage(activePage - 1)}
          >
            Back
          </button>

          <div>
            {activePage === pages.length - 1 ? (
              "Done!"
            ) : (
              <>
                Question {activePage + 1} / {pages.length}
              </>
            )}
          </div>

          <button
            style={{
              visibility:
                activePage === pages.length - 1 ? "hidden" : undefined,
            }}
            disabled={!validPages.has(activePage)}
            onClick={() => setActivePage(activePage + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
