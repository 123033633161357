import styles from "./Footer.module.scss";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { State } from "../../../state/store";

export interface FooterProps {
  children?: ReactNode;
}

export function Footer(props: FooterProps) {
  const state = useSelector<State, State>((s) => s);

  return (
    <div className={styles.footer}>
      <div>Page 1 of 3</div>

      <div>{props.children}</div>

      <div className={styles.pdfId}>{state.pdfId || "8fab28"}</div>
    </div>
  );
}
