import styles from "./SelectList.module.scss";
import { useState } from "react";
import cn from "classnames";

export interface SelectItem {
  id: any;
  title: string;
}

export interface SelectListProps {
  items: SelectItem[];
  onChange?: (item?: SelectItem) => void;
}

export function SelectList(props: SelectListProps) {
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>();

  return (
    <>
      {props.items.map((item) => {
        return (
          <div
            key={item.id}
            className={cn(
              styles.skillRow,
              selectedItemId === item.id ? styles.active : undefined
            )}
            onClick={() => {
              setSelectedItemId(item.id);
              if (props.onChange)
                props.onChange(props.items.find((i) => item.id === i.id));
            }}
          >
            <span>{item.title}</span>
          </div>
        );
      })}
    </>
  );
}
