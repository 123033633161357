import { technologyContent } from "../../content";
import { TechnologyCategoryId } from "../../../wizard/content";

export interface TechnologyProps {
  category: TechnologyCategoryId;
  techId: number;
}
export function Technology(props: TechnologyProps) {
  const category = technologyContent[props.category];
  const content = category.content[props.techId];

  return (
    <div>
      <h4>{category.title}</h4>
      <p>{content.subtitle}</p>
      {content.content}
    </div>
  );
}
