import styles from "./Debug.module.scss";
import { Wizard } from "../wizard/Wizard";

import React from "react";
import { CV } from "../cv/pages/CV";

export function DebugPage() {
  return (
    <div className={styles.page}>
      <div className={styles.wizard}>
        <Wizard />
      </div>
      <div className={styles.cv}>
        <CV />
      </div>
    </div>
  );
}
