import styles from "./Download.module.scss";
import cn from "classnames";
import { CvGenerationResult } from "../../../api";

export interface DownloadProps {
  result: CvGenerationResult;
}

export function Download(props: DownloadProps) {
  return (
    <>
      <h1>Ready to download!</h1>
      <p>
        A customized version of my CV has been prepared for you to download.
      </p>

      <div className={styles.downloadContainer}>
        <a
          className={styles.downloadButton}
          href={props.result.fileUrl}
          download={props.result.fileName}
        >
          <span className={cn(styles.icon, "material-symbols-outlined")}>
            download
          </span>
          <span className={styles.filename}>{props.result.fileName}</span>
        </a>
      </div>
    </>
  );
}
