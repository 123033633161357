import { CaseStudyContent, StoryCategoryContent } from "./types";
import { CaseStudyId } from "../../../wizard/content";

export const caseStudyContent: StoryCategoryContent = {
  title: "A case study",
  storiesById: {
    [CaseStudyId.marketAggregation]: {
      subtitle: "Increasing efficiency on the stock market",
      content: (
        <>
          On the stock market, you cannot buy fractions of shares. That doesn't
          stop some investing products from offering that as a feature - which
          makes investing more accessible.
          <br />
          A typical approach to allowing that is to run a corporate book. If an
          investor wants half a share, we can buy 1 share and keep the remaining
          0.5. The problem is that these remainders can build up very quickly,
          1,000 investors purchasing half a share in individual trades would
          result in 500 shares leftover.
          <br />
          That was how things worked beforehand. My role was to deliver a new
          system that could aggregate orders together, which would increase the
          efficiency and reduce the remainder the corporate book would need to
          manage. With the new trading system we built in C# 1,000 orders for
          half a share could be aggregated into a single order on market for 500
          shares.
          <br />
          We were able to launch this system live and seamlessly switch trading
          to this new model, which achieved a massive efficiency gain in
          trading.
        </>
      ),
    },
    [CaseStudyId.dataGrid]: {
      subtitle: "Serious database work",
      content: (
        <>
          Almost every product needs to display a column/row based dataset. Not
          every product allows the user to create whatever columns they want.
          <br />
          By supporting some basic data types such as text, numbers and
          dropdowns, I built a backend to render results for the grid.
          <br /> Doesn't sound hard yet? Well this grid could have tens of
          thousands of rows, with every mix of custom columns present, and our
          users needed to be able to filter and sort on every column they
          created.
          <br />
          The challenge of this was designing a database schema that could not
          only store such configurable shapes of data, but could do so in a
          manner where it could be efficiently indexed to serve realtime
          queries.
        </>
      ),
    },
    [CaseStudyId.cashDistribution]: {
      subtitle: "Mistakes cost money",
      content: (
        <>
          An overseas trading partner had a serious issue with their trading
          system where trades were executed at incorrect prices.
          <br />
          To make good on the effected orders, we needed to distribute
          reparation payments from our partner to our investors. When an issue
          occurs like this, many things need dealt with. The value of trade
          settlements (payment for trades) need confirmed and reconciled to make
          sure the custody of customer assets is correct.
          <br />I also took up the responsibility of calculating the reparation
          payments to each of the over 4 thousand effected customers, and the
          ledger transactions to effect this were completed with 200 lines of
          code!
        </>
      ),
    },
  },
};
