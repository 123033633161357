import { ReactNode } from "react";
import { TechnologyCategoryContent, TechnologyContent } from "../types";
import { BackendTechnology } from "../../../wizard/content";

export const backendContent: TechnologyCategoryContent<BackendTechnology> = {
  title: "Systems development",
  content: {
    [BackendTechnology.notRelevant]: {
      subtitle: "Asdf",
      content: <></>,
    },
    [BackendTechnology.dotnet]: {
      subtitle: "C# .NET",
      content: (
        <>
          .NET is one of my strongest languages, having spent a decade working
          with it. I find it most suited to high performance backend services
          and API's where reliability and performance are top priorities. I also
          find it to be a very maintainable choice, making it good for complex
          projects.
        </>
      ),
    },
    [BackendTechnology.python]: {
      subtitle: "Python",
      content: (
        <>
          My go-to scripting language, or for completing data analysis tasks.
          Saying that, I've worked on large python API codebases that handle
          mission critical tasks including maintaining a transactions ledger for
          financial assets.
        </>
      ),
    },
    [BackendTechnology.c]: {
      subtitle: "C and C++",
      content: (
        <>
          Ah this old chestnut. I'm not a huge fan of C++, although I can make
          myself useful in it. I find a special kind of enjoyment in writing C
          though. Working with C helps build a deep understanding of how
          computers function, something you don't get in high level languages.
          When it comes to embedded programming (STM32 or Arduino) C is always a
          sane option.
        </>
      ),
    },
    [BackendTechnology.java]: {
      subtitle: "Java",
      content: (
        <>
          Once upon a time, when Java 8 was coming out, I built an API that
          could render stop-motion animations into a video clip.
          <br />I spent a lot of time writing Java when it was the primary
          language for Android, but quickly graduated to Kotlin.. and then
          stopped working with Android apps so often.
          <br />I wouldn't say it's my favorite language, but I can make myself
          useful in Java.
        </>
      ),
    },
    [BackendTechnology.other]: {
      subtitle: "Knowledge and experience",
      content: (
        <>
          My deep understanding of memory and code execution comes from writing
          C, and my effectiveness as an engineer comes from writing C# code for
          a decade.
          <br />
          The languages in use don't matter to a good engineer. There's usually
          a right tool for a job, and being able to answer that question
          accurately for a given task shows a breadth of experience.
        </>
      ),
    },
  },
};
