import { ContentChoices } from './content';


export enum CaseStudyId {
  marketAggregation,
  dataGrid,
  cashDistribution,
}

export enum PersonalStoryId {
  crashedPlane,
  bank,
  smoke,
  planeBuild,
}

export enum StoryCategoryId {
  caseStudy,
  personalStory,
}

export const contentByCategory: {
  [K in StoryCategoryId]: ContentChoices<StoryCategoryId, number>;
} = {
  [StoryCategoryId.caseStudy]: {
    id: StoryCategoryId.caseStudy,
    title: "What project sounds interesting?",
    description: "I'll include this as a case study for you to read.",
    options: [
      {
        id: CaseStudyId.marketAggregation,
        title: "Stock market order aggregation",
      },
      {
        id: CaseStudyId.dataGrid,
        title: "Customizable column / row data grid",
      },
      {
        id: CaseStudyId.cashDistribution,
        title: "Distributing cash payments",
      },
    ],
  },
  [StoryCategoryId.personalStory]: {
    id: StoryCategoryId.caseStudy,
    title: "What story sounds interesting?",
    description: "",
    options: [
      {
        id: PersonalStoryId.crashedPlane,
        title: "I almost crashed a plane",
      },
      {
        id: PersonalStoryId.bank,
        title: "Why a bank asked me to unpublish something",
      },
      {
        id: PersonalStoryId.smoke,
        title: "Diagnosing the magic smoke (electronics)",
      },
      {
        id: PersonalStoryId.planeBuild,
        title: "I'm assembling a kit plane in my garage",
      },
    ],
  },
};
