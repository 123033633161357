import { SelectList } from "../../components/SelectList/SelectList";
import { setContentChoice } from "../../../state/actions";
import { useDispatch } from "react-redux";
import { contentByCategory, StoryCategoryId } from "../../content";
import { Page, PageForwardProps } from "../Page";
import { useEffect, useState } from "react";

export interface SelectListPageProps extends PageForwardProps {
  category: StoryCategoryId;
}

export function ContentChooser(props: SelectListPageProps) {
  const [selectedContentId, setSelectedContentId] = useState<
    number | undefined
  >();

  const content = contentByCategory[props.category];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedContentId) return;

    dispatch(
      setContentChoice({
        category: props.category,
        contentId: selectedContentId,
      })
    );
  }, [selectedContentId]);

  return (
    <Page {...props} isValid={selectedContentId !== undefined}>
      <h1>{content.title}</h1>
      <p>{content.description}</p>

      <SelectList
        items={content.options}
        onChange={(content) => {
          if (!content) return;

          setSelectedContentId(content.id);
        }}
      />
    </Page>
  );
}
